import { Box, Center, Flex, Heading, Icon, SimpleGrid, Text } from "@chakra-ui/react"
import { FunctionComponent, useRef } from "react"
import { ServerAchievement, getAchievements } from "../api/game"
import { useQuery } from "@tanstack/react-query"
import Panel from "../components/common/Panel"
import Progress from "../components/common/ProgressWithText"
import { GiAchievement } from "react-icons/gi"
import { FaLock, FaLockOpen } from "react-icons/fa"
import { useTranslation } from "react-i18next"
import { Tooltip } from "@chakra-ui/react"
import Number from "../components/common/Number"
import { useGameId } from "../hooks/query"

const AchievementPage: FunctionComponent = () => {
    const gameId = useGameId()
    const { data, isLoading } = useQuery({
        queryKey: ["achievements", gameId],
        queryFn: () => getAchievements(gameId),
        gcTime: 0,
    })
    return (
        <Box>
            <Heading as="h2">Achievements</Heading>
            <Text mb="2">Each achievements gives you 0.5% increase to storage and production.</Text>
            <SimpleGrid columns={[1, 2, 3]} gap={2}>
                {isLoading && <h1>Loading...</h1>}
                {data?.achievements &&
                    data.achievements.map((achievement) => <AchievementBox key={achievement.name} {...achievement} />)}
            </SimpleGrid>

            {/* {data?.stats && (
                <>
                    <Heading as="h2">Feats</Heading>
                    <Text>
                        <Number value={data?.stats.games} /> game(s) played.
                    </Text>
                    <Text>
                        <Number value={data?.stats.wins} /> game(s) won.
                    </Text>
                    <Text>
                        <Number value={data?.stats.bestPlayer} /> award(s) of best player.
                    </Text>
                </>
            )} */}
        </Box>
    )
}

const AchievementBox: FunctionComponent<ServerAchievement> = ({ name, earned, goal, progress, visible, unlock }) => {
    let bgColor = "orange.200"
    let color = "black"
    if (earned) {
        bgColor = "orange.500"
        color = "orange.100"
    }
    // else if (!visible)
    //     bgColor = "gray.300"

    const { t } = useTranslation()

    return (
        <Panel variant="achievement" bgColor={bgColor} color={color}>
            <Flex justifyContent={"space-between"}>
                <Box flexGrow={1}>
                    <Center borderBottom="2px solid" borderColor="orange.600" w="full" p="1">
                        <Text fontSize="md">{visible ? t(`achievements.${name}.title`) : "?????"}</Text>
                    </Center>
                    <Center p="1">
                        <Text fontSize={"sm"}>
                            {visible
                                ? t(`achievements.${name}.description`, {
                                      goal: goal,
                                  })
                                : "????????????????"}
                        </Text>
                    </Center>
                </Box>

                <Flex justifyContent={"center"} alignItems={"center"} ms="2">
                    {!unlock && earned && (
                        <Tooltip label="Earned" hasArrow>
                            <span>
                                <Icon as={GiAchievement} boxSize="30" />
                            </span>
                        </Tooltip>
                    )}
                    {unlock && earned && (
                        <Tooltip label="This achievement unlocked something!" hasArrow>
                            <span>
                                <Icon as={FaLockOpen} boxSize="30" />
                            </span>
                        </Tooltip>
                    )}
                    {unlock && !earned && (
                        <Tooltip label="This achievement unlock something..." hasArrow>
                            <span>
                                <Icon as={FaLockOpen} boxSize="30" />
                            </span>
                        </Tooltip>
                    )}
                </Flex>
            </Flex>

            {!earned && progress !== null && goal && <Progress value={progress} total={goal} />}
        </Panel>
    )
}

export default AchievementPage
